import React, { Component } from 'react';
import { getPreviewArticleOrCategorySlugs } from '../../utilities';
import Slider from 'react-slick';
import { Image } from '../../BlockLibrary/Common/Image';

const contentful = require("contentful");

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
     host: 'preview.contentful.com'
});

class PreviewBrowseContentByCategory extends Component {
     constructor(props) {
          super(props);

          this.state = {
               topCategories: [],
               activeCategory: '',
               titleBrowes: ''
          }
     }

     componentDidMount() {
          client
               .getEntries({
                    'content_type': 'hzCategory',
                    'fields.isTopCategory[in]': 'true',
                    'include': 10,
                    'limit': 5,
                    order: 'sys.createdAt,sys.id'
               })
               .then(entries => {
                    this.setState({ topCategories: entries.items, activeCategory: entries.items[0].sys.id })
               }).catch(err => {
                    // console.log(err)
               });
          client
               .getEntry('LGzdBMq54MS0y5Lu1pMzW')
               .then(res => {
                    this.setState({ ...this.state, titleBrowes: res.fields.contentName })

               }).catch(err => {
                    console.log(err)
               });
     }

     browseCategory = (e, categoryName) => {
          e.preventDefault();
          this.setState({ activeCategory: categoryName })
     }

     render() {

          var topCategories = this.state.topCategories;
          var activeCategory = this.state.activeCategory;
          const settings = {
               autoplay: false,
               dots: false,
               arrows: false,
               infinite: true,
               speed: 500,
               slidesToShow: 1,
          };

          if (topCategories.length === 0) {
               return <></>;
          }

          var featuredArticles = [];
          topCategories.forEach(topCategory => {
               if (topCategory.sys.id == activeCategory) {
                    featuredArticles = topCategory.fields.categoryFeaturedArticle;
               }
          })
          // const firstRecentArticleSlug = '/healthzone/' + getPreviewArticleOrCategorySlugs(top7LatestArticles[0].fields.category).reverse().join('/') + '/' + top7LatestArticles[0].fields.slug + '/';
          topCategories = [topCategories[3], topCategories[1], topCategories[2], topCategories[0], topCategories[4]];
          return (
               <section className={`home-browse`}>
                    <div className="container slider-container">
                         <h2>{this.state.titleBrowes}</h2>
                         <div className="desktop">
                              <div className="browse-title">
                                   {topCategories && topCategories.map((topCategory, i) => {
                                        return (
                                             <a href="#"
                                                  className={activeCategory == topCategory.sys.id ? 'active' : ''}
                                                  onClick={(e) => this.browseCategory(e, topCategory.sys.id)} key={i}
                                             >
                                                  {topCategory.fields.categoryName}
                                             </a>
                                        )
                                   })}
                              </div>
                              <div className="browse-article">
                                   <div className="row">
                                        {featuredArticles && featuredArticles.map(({ fields: featuredArticle }, i) => {
                                             let slugs = '/healthzone/' + getPreviewArticleOrCategorySlugs(featuredArticle.category).reverse().join('/') + '/' + featuredArticle.slug + '/';

                                             return (
                                                  <div className="col-sm-3" key={i}>
                                                       <div className="article-detail">
                                                            {featuredArticle.featuredImage && (
                                                                 <a href={slugs} tabIndex={-1}>
                                                                      <div className="image-wrap">
                                                                           <Image image={featuredArticle.featuredImage} />
                                                                      </div>
                                                                 </a>
                                                            )}
                                                            <div className="content">
                                                                 <a href={slugs}>
                                                                      <h3>{featuredArticle.contentName}</h3>
                                                                 </a>
                                                                 {featuredArticle.shortDescription && <p>{featuredArticle.shortDescription}</p>}
                                                            </div>
                                                       </div>
                                                  </div>
                                             )
                                        })}
                                   </div>
                              </div>
                         </div>
                         <div className="mobile">
                              <div className="browse-title">
                                   <Slider slidesToShow={3}>
                                        {topCategories && topCategories.map((topCategory, i) => {
                                             return (
                                                  <a href="#"
                                                       className={activeCategory == topCategory.sys.id ? 'active' : ''}
                                                       onClick={(e) => this.browseCategory(e, topCategory.sys.id)} key={i}
                                                  >
                                                       {topCategory.fields.categoryName}
                                                  </a>
                                             )
                                        })}
                                   </Slider>
                              </div>
                              <div className="browse-article">
                                   <Slider {...settings} slidesToShow={1.2}>
                                        {featuredArticles && featuredArticles.map(({ fields: featuredArticle }, i) => {
                                             let slugs = '/healthzone/' + getPreviewArticleOrCategorySlugs(featuredArticle.category).reverse().join('/') + '/' + featuredArticle.slug + '/';

                                             return (
                                                  <div className="article-detail" key={i}>
                                                       {featuredArticle.featuredImage && (
                                                            <a href={slugs} tabIndex={-1}>
                                                                 <div className="image-wrap">
                                                                      <Image image={featuredArticle.featuredImage} />
                                                                 </div>
                                                            </a>
                                                       )}
                                                       <div className="content">
                                                            <a href={slugs}>
                                                                 <h3>{featuredArticle.contentName}</h3>
                                                            </a>
                                                            {featuredArticle.shortDescription && <p>{`${featuredArticle.shortDescription}`.substr(1, 120)}...</p>}
                                                       </div>
                                                  </div>
                                             )
                                        })}
                                   </Slider>
                              </div>
                         </div>
                    </div>
               </section>
          )
     }
}

export default PreviewBrowseContentByCategory;
