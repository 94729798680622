import React, { Component } from 'react';
import { getPreviewArticleOrCategorySlugs } from '../../utilities';
import Slider from 'react-slick';
import get from 'lodash/get';
import { Image } from '../../BlockLibrary/Common/Image';

const contentful = require("contentful");

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
     host: 'preview.contentful.com'
});

class PreviewNewArticles extends Component {
     constructor(props) {
          super(props);

          this.state = {
               top7LatestArticles: []
          }
     }

     componentDidMount() {
          client
               .getEntries({
                    'content_type': 'hzArticle',
                    order: '-sys.createdAt', // order descending by creation timestamp
                    'include': 10,
                    'limit': 7
               })
               .then(entries => {
                    this.setState({ top7LatestArticles: entries.items })
               }).catch(err => {
                    // console.log(err)
               });
     }

     render() {
          const top7LatestArticles = this.state.top7LatestArticles;

          if (top7LatestArticles.length === 0) {
               return <></>;
          }

          const firstRecentArticle = top7LatestArticles[0].fields;
          const firstRecentArticleSlug = '/healthzone/' + getPreviewArticleOrCategorySlugs(top7LatestArticles[0].fields.category).reverse().join('/') + '/' + top7LatestArticles[0].fields.slug + '/';
          const settings = {
               autoplay: false,
               dots: false,
               arrows: true,
               infinite: true,
               speed: 500,
               slidesToShow: 1,
          };

          return (
               <section className={`home-articles recent-articles`}>
                    <div className="container recent-articles-container">
                         <div className="first-article">
                              <div className="row">
                                   <div className="col-lg-8 first-article-left">
                                        {firstRecentArticle.featuredImage && (
                                             <a href={firstRecentArticleSlug} tabIndex={-1}>
                                                  <Image image={firstRecentArticle.featuredImage} />
                                             </a>
                                        )}
                                   </div>
                                   <div className="col-lg-4 first-article-right">
                                        <div className="content">
                                             {firstRecentArticle.contentName && (
                                                  <a href={firstRecentArticleSlug}>
                                                       <h1>{firstRecentArticle.contentName}</h1>
                                                  </a>
                                             )}
                                             {firstRecentArticle.shortDescription && <p>{firstRecentArticle.shortDescription}</p>}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="container">
                         <div className="desktop">
                              <div className="recent-article-slider">
                                   <div className="row articles-wrap">
                                        {
                                             top7LatestArticles.slice(1).map(({ fields: article }, i) => {
                                                  let slugs = '/healthzone/' + getPreviewArticleOrCategorySlugs(article.category).reverse().join('/') + '/' + article.slug + '/';

                                                  return (
                                                       <div className="col-sm-4 article-block" key={i}>
                                                            <div className="article-slider-detail article-detail">
                                                                 {article.featuredImage && (
                                                                      <a href={slugs}>
                                                                           <div className="image-wrap">
                                                                                <Image image={article.featuredImage} />
                                                                           </div>
                                                                      </a>
                                                                 )}
                                                                 <div className="content">
                                                                      {article.contentName && (
                                                                           <a href={slugs}>
                                                                                <h3>{article.contentName}</h3>
                                                                           </a>
                                                                      )}
                                                                      {article.shortDescription && <p>{article.shortDescription}</p>}
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  )
                                             })
                                        }
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="mobile">
                         <div className="recent-article-slider">
                              <div className="slider-container">
                                   <div className="recent-article-slider">
                                        <Slider {...settings} slidesToShow={1.2}>
                                             {
                                                  top7LatestArticles.slice(1).map(({ fields: article }, i) => {
                                                       let slugs = '/healthzone/' + getPreviewArticleOrCategorySlugs(article.category).reverse().join('/') + '/' + article.slug + '/';
                                                       return (
                                                            <div className="article-slider-detail" key={i}>
                                                                 <a href={slugs}>{article.featuredImage && <Image image={article.featuredImage} />}</a>
                                                                 <div className="content">
                                                                      <a href={slugs}>{article.contentName && <h3>{article.contentName}</h3>}</a>
                                                                      {article.shortDescription && <p>{article.shortDescription}</p>}
                                                                 </div>
                                                            </div>
                                                       )
                                                  })
                                             }
                                        </Slider>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          )
     }
}

export default PreviewNewArticles;
