import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from '../Header/index';
import Footer from '../footer';
import Blocks from '../blocks';
import Layout from '../layout';
import Seo from '../seo';
import { getPreviewPage } from '../../state/app';
import PreviewNewArticles from '../PreviewLibrary/HealthzoneHome/PreviewNewArticles';
import PreviewBrowseContentByCategory from '../PreviewLibrary/HealthzoneHome/PreviewBrowseContentByCategory';
import PreviewPgHome from '../PreviewLibrary/PetGazette/PreviewPgHome';

class Page extends Component {
     render() {
          const { page, isLoading } = this.props;

          let stringRows = JSON.stringify(page.fields.rows);

          if (stringRows.includes('##STATE##') && page.fields?.contentName) {
               stringRows = stringRows.replace(/##STATE##/g, page.fields.contentName);
               page.fields.rows = JSON.parse(stringRows);
          }

          return (
               <>
                    <Layout>
                         <Seo
                              title={page.fields.seoTitleTag}
                              canonical={page.fields.seoCanonicalUrl}
                              description={page.fields.seoMetaDescription}
                              robots={page.fields.robotsDirective}
                              image={page.fields.socialImage}
                              googleSiteVerificationContent={page.fields.googleSiteVerificationContent}
                         />
                         {
                              isLoading && (
                                   <div className="page-loading">Loading ...</div>
                              )
                         }
                         <Header specificPhone={page.fields.specificPhone} header={page.fields.header}></Header>
                         <main id="main-content" role="main">
                              {
                                   page.fields.slug == 'healthzone' ? (
                                        <>
                                             <PreviewNewArticles></PreviewNewArticles>
                                             {page.fields.rows && <Blocks blocks={page.fields.rows}></Blocks>}
                                             <PreviewBrowseContentByCategory></PreviewBrowseContentByCategory>
                                        </>
                                   ) : (
                                        page.fields.slug == 'pet-gazette' ? (
                                             <PreviewPgHome></PreviewPgHome>
                                        ) : (
                                             page.fields.rows && <Blocks blocks={page.fields.rows}></Blocks>
                                        )
                                   )
                              }
                         </main>
                         <Footer
                              specificPhone={page.fields.specificPhone}
                              footer={page.fields.footer}
                         >
                         </Footer>
                    </Layout>
               </>
          )
     }

}


Page.propTypes = {
     page: PropTypes.object,
}

function mapStateToProps(state) {
     return {
          page: getPreviewPage(state)
     };
}

export default connect(mapStateToProps)(Page);
